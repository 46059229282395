<template>
    <div>
        <Header></Header>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2 text-center">

                <h1>Stellenangebote</h1>
                <h2>Waschprofi werden</h2>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <p class="demi">
                    waschprofis.de steht für erstklassige Aufbereitung und Reinigung aller Fahrzeugtypen und bietet mit dem Autohaus Israel und einer freien Werkstatt alle Leistungen rund ums Auto.<br>
                    Autopflege heißt mehr als nur durch die Wäsche fahren. Ob Lack, Cockpit, Polster, Leder oder Motor – dank unserer Waschprofis erstrahlen die Gebrauchtwagen unserer Kunden in neuem Glanz.<br>
                    <br>
                    Unterstützen Sie unsere Waschprofis mit Ihrem Blick fürs Detail!<br>
                    Wir suchen zum nächstmöglichen Zeitpunkt eine/einen
                </p>
                     <h3>FAHRZEUGAUFBEREITER/-IN in Vollzeit oder Teilzeit</h3>
                     <ul class="list-unstyled">
                         <li><strong>IHRE AUFGABEN:</strong></li>
                         <li>+ Aufbereitung von Neu- und Gebrauchtwagen für den Verkauf </li>
                         <li>+ Aufarbeitung von Gebrauchtfahrzeugen nach Neuwagenkriterien </li>
                         <li>+ Fahrzeugwäsche und Fuhrparkpflege </li>
                         <li>+ Innen- und Außenreinigung, Pflege und Versiegelung </li>
                         <li>+ Motorwäsche </li>
                         <li>+ Cockpit- und Scheibenpflege </li>
                         <li>+ Reifen- und Felgenpflege </li>
                         <li>+ Einhaltung von Terminvorgaben</li>
                     </ul>
                     <ul class="list-unstyled">
                         <li><strong>IHR PROFIL:</strong></li>
                         <li>+ sorgfältige und selbstständige Arbeitsweise </li>
                         <li>+ Berufserfahrung im Automobilbereich </li>
                         <li>+ Freude am Umgang mit Fahrzeugen </li>
                         <li>+ hohes Qualitätsbewusstsein </li>
                         <li>+ Teamfähigkeit, Zuverlässigkeit und Flexibilität </li>
                         <li>+ Führerschein Klasse B </li>
                     </ul>
                     <p>
                         <span class="demi">Verstärken Sie unsere Fahrzeugaufbereitung mit Ihrer Gewissenhaftigkeit und Einsatzbereitschaft!</span><br>
                         <span class="demi">Wir zahlen überdurchschnittliches Gehalt</span><br>
                         <br>
                         Wir freuen uns auf Ihre Bewerbung – bevorzugt<br>
                         <table class="table table-borderless table-sm">
                             <tr>
                                 <td scope="col"><span class="demi">per E-Mail an:</span></td>
                                 <td scope="col"><a href="mailto:info@waschprofis">info@waschprofis.de</a></td>
                             </tr>
                             <tr>
                                 <td scope="col"><span class="demi">per Post an:</span></td>
                                 <td scope="col">Autohaus Israel GmbH & Co. KG<br>
                                     z.H. Kay Pelzer<br>
                                     Dohnaer Straße 310<br>
                                    01257 Dresden
                                </td>
                             </tr>
                         </table>
                    </p>

                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    export default {
        name: 'JobOffer',
        components: {
            Header,
            Footer,
        },
    }
</script>
<style lang="scss" scoped>
    h1{
        font-weight:400;
        font-size: 21px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    h2{
        font-family: 'futura-pt-bold';
        font-size: 60px;
        letter-spacing: 1.5px;
        margin-bottom: 50px;
    }

    h3{
        font-size: 30px;
        letter-spacing: 0.75px;
        font-weight: 600;
        color: #456FEF;
        margin-top:1.25em;
        margin-bottom:1.25em;
    }

    p{
        font-size: 22px;
        letter-spacing: 0.55px;
    }

    ul{
        margin-bottom:2em;

        li{
            font-size:22px;
            font-weight:400;
        }
    }

    table{
        margin-bottom:110px;
    }


</styles>